import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

export const globalSlice = createSlice({
    name: 'global',
    initialState: {
        loading: []
    },

    reducers: {
        addLoading:(state, action) => {
            const message = action.payload || "Please do not refresh the screen";
            const loading = [...state.loading, message]
            state.loading = loading;
        },
        removeLoading: (state, action) =>{
            const message = action.payload || "Please do not refresh the screen";
            const index = state.loading.indexOf(message);
            const loading = [...state.loading]
            if (index >= 0){
                loading.splice(index, 1);
            }

            state.loading = loading;
        }
    },
});

export const { addLoading, removeLoading } = globalSlice.actions;

export const selectLoading = (state) => state.global.loading;
export const selectLastLoading = state => state.global.loading.length > 0 ? _.last(state.global.loading) : null;

export default globalSlice;