import React, { useCallback, useEffect, useMemo, useState } from 'react'
import ReactDOM from 'react-dom';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import config from '../../../config/config';
import MapFailure from '../component/map-failure';
import LocationOnIcon from '../../../styles/images/track-elements-location-on.svg';
import LocationOffIcon from '../../../styles/images/track-elements-location-off.svg';
import { Button } from 'react-bootstrap';
import _ from 'lodash';
import RenderIf from '../../../components/render-if';
import UserMarker from './user-marker';
import Searchbox from './search-box';

const MAP_OFFSET = -70;
const Map = ({markers, showCurrentLocation, onClick, onPan, onSearchAddress, onUserMarkerError, centerMaker=false, zoomOnPan=true}) => {
  const libraries = useMemo(()=> ['places'], []);
  const { isLoaded, loadError } = new useLoadScript({
    id: 'google-map-script',
    googleMapsApiKey: config.googleMapsApiKey,
    libraries: libraries
  });

  const [map, setMap] = useState(null);
  const [dragging, setDragging] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(null);

  const onLoad = React.useCallback((map) => {
    const bounds = new window.google.maps.LatLngBounds();
    map.setCenter(bounds.getCenter());
    map.setZoom(config.defaultMapZoom);
    setMap(map);
  }, []);

  const onMapClick = async (event) => {
    if (_.isFunction(onClick)){
      onClick(event);
    }
  }

  const onBoundsChanged = useCallback(() => {
    if (map && _.isFunction(onPan)){
      const center = map.getCenter();
      onPan({lat: center.lat(), lng: center.lng()});
    }
  }, [map, onPan]);

  const handleSearchAddress = useCallback(event => {
    if (map && _.isFunction(onSearchAddress)){
      onSearchAddress(event)
    }
  }, [map, onSearchAddress]);

  const handleUserMarkerChange = useCallback(({lat, lng}) => {
    setCurrentLocation({lat, lng});
  }, []);

  useEffect(() => {
    if (map && !dragging) {
      const bounds = new window.google.maps.LatLngBounds();
      for (const marker of markers) {
        const bound = new window.google.maps.LatLng(marker.position.lat, marker.position.lng);
        bounds.extend(bound);
      }

      if (markers.length === 1) {
        map.setCenter(bounds.getCenter());
        if (zoomOnPan){
          map.setZoom(config.defaultMapZoom);
        }
        if (!centerMaker){
          map.panBy(0, MAP_OFFSET);
        }
      } else {  
        map.fitBounds(bounds);
        if (!centerMaker){
          map.panBy(0, MAP_OFFSET);
        }

        if (loaded){
          if (zoomOnPan && map.getZoom() > config.defaultMapZoom) {
            map.setZoom(config.defaultMapZoom);
          }
        }else{
          window.google.maps.event.addListenerOnce(map, "idle", function() {
            if (zoomOnPan && map.getZoom() > config.defaultMapZoom) {
              map.setZoom(config.defaultMapZoom);
            }
          });
        }
      }

      setLoaded(true);
    }
  }, [markers, map, loaded, dragging, centerMaker, zoomOnPan]);

  useEffect(()=>{
      if(map && showCurrentLocation){
        const hasLocation = currentLocation?.lat && currentLocation?.lng;
        const moveToCurrentLocation = () =>{
            if (!hasLocation) return;
            const bounds = new window.google.maps.LatLngBounds();
            const bound = new window.google.maps.LatLng(currentLocation?.lat, currentLocation?.lng);
            bounds.extend(bound);
            map.setCenter(bounds.getCenter());
            onBoundsChanged();
        }
        const centerControlDiv = document.createElement("div");
        ReactDOM.render(<Button className='bg-transparent shadow-none border-0 p-0 w-auto h-auto mb-2' onClick={()=>moveToCurrentLocation()}><img src={hasLocation ? LocationOnIcon : LocationOffIcon} alt='My Location' /></Button>,centerControlDiv)
        if (map.controls[window.google.maps.ControlPosition.BOTTOM_RIGHT].length > 0){
          map.controls[window.google.maps.ControlPosition.BOTTOM_RIGHT].pop();
        }
        map.controls[window.google.maps.ControlPosition.BOTTOM_RIGHT].push(centerControlDiv);
      }

  }, [map, showCurrentLocation, currentLocation, onBoundsChanged]);
  
  const onUnmount = React.useCallback(function callback() {
    setMap(null)
  }, [])

  const renderMap = () => {
    return <GoogleMap
    mapContainerClassName='w-100 h-100'
    zoom={config.defaultMapZoom}
    onLoad={onLoad}
    onUnmount={onUnmount}
    onDrag={onBoundsChanged}
    onIdle={onMapClick}
    onDragStart={() => _.isFunction(onPan) && setDragging(true)}
    onDragEnd={() => _.isFunction(onPan) && setDragging(false)}
    options={{
      disableDefaultUI: true,
    }}
  >
    <>
      <RenderIf condition={!!onSearchAddress}>
        <Searchbox onSelectChange={handleSearchAddress} map={map}/>
      </RenderIf>
      <RenderIf condition={!!markers}>
        {markers.map((marker, index) => <Marker zIndex={index+1} icon={marker.icon} position={marker.position} label={marker.label ?? undefined} key={`marker_${index}`} />)}
      </RenderIf>

      <RenderIf condition={showCurrentLocation}>
        <UserMarker onError={onUserMarkerError} onChange={handleUserMarkerChange}></UserMarker>
      </RenderIf>
    </>
  </GoogleMap>
  }
  
  if(loadError) {
    return <MapFailure />
  }

  return isLoaded ? renderMap() : <div></div>
}

Map.defaultProps = {
  markers: []
}

export default React.memo(Map)