export const googleAutocomplete = async text =>
  new Promise((resolve, reject) => {
    if (!text) {
      return reject("Need valid text input")
    }

    if (typeof window === "undefined") {
      return reject("Need valid window object")
    }

    if (!window.google){
        return reject("google object is not defined");
    }

    try {
      new window.google.maps.places.AutocompleteService().getPlacePredictions(
        { input: text, componentRestrictions: { country: "au" } , types: ["geocode", "establishment"]},
        resolve
      )
    } catch (e) {
      reject(e)
    }
  })


  export const getPlaceDetails = async (map, placeId) => new Promise((resolve, reject)=>{
    try {
      const service =  new window.google.maps.places.PlacesService(map);

      service.getDetails({placeId}, (result, status) =>{
        if (status !== 'OK') {
          reject(status);
          return;
        }
        resolve(result);
      });
    } catch (e) {
      reject(e)
    }
  })
  