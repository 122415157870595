import { configureStore } from '@reduxjs/toolkit';
import tmpSlice from '../feature/track-my-patrol/tmp.slice';
import rsaSlice from '../feature/rsa/rsa.slice';
import globalSlice from './global.slice';

const store = configureStore({
    reducer: {
        global: globalSlice.reducer,
        tmp: tmpSlice.reducer,
        rsa: rsaSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: false,
        }),
});

export default store;