import React from 'react';
import { useEffect } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { setShowAlert } from '../tmp.slice';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

const SelectLocation = ({address, disabled, onClick}) =>{
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const handleClick = () => {
        if (_.isFunction(onClick)){
            onClick();
        }
    }

    useEffect(()=>{
        dispatch(setShowAlert(false));
    }, [dispatch]);
    

    return  <div className='position-relative p-3 select-location'>
                <Container>
                    <Row className='mb-3 pb-1'>
                        <Col xs='12'>
                            {t('TMP.location')}
                        </Col>
                        <Col xs='12'>
                           {address}
                        </Col>
                        
                    </Row>
                    <Row>
                        <Col xs='12'>
                            <Button className='w-100 font-18 btn-rouded' onClick={handleClick} disabled={disabled}>
                                {t('TMP.use_location')}
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </div>
}

export default SelectLocation;