import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment-timezone';
import { getJobByGuid, getProvider } from '../../services/api';
import { addLoading, removeLoading } from '../../store/global.slice';
import PinLocation from '../../styles/images/pin-car.svg';
import ProviderLocation from '../../styles/images/pin-patrol.svg';
import { formatAddress, setMarkerIcon, stateToProgress } from '../../utils/helpers';
import Logger from '../../utils/logger';
import { JobStates } from './constants/job-state';
import { ProgressSteps } from './constants/progress-step';
import { Status } from './constants/status';

const logger = new Logger();
export const tmpSlice = createSlice({
    name: 'tmp',
    initialState: {
        status: Status.IDLE,
        markers: [],
        job: null,
        provider: null,
        isShowSearch: false,
        alert: null,
        showAlert: false,
        progress: ProgressSteps.NOT_ACTIVE
    },

    reducers: {
        setStatus: (state, action) => {
            state.status = action.payload
        },
        setJob: (state, action) => {
            state.job = action.payload.job;
            state.progress = action.payload.progress;
        },
        setProvider: (state, action) => {
            state.provider = action.payload
        },
        setProgress: (state, action) => {
            state.progress = action.payload
        },
        setMarkers: (state, action) => {
            state.markers = action.payload;
        },
        showSearch: (state, action) => {
            state.isShowSearch = action.payload;
        },
        setAlert: (state, action) => {
            state.alert = action.payload;
        },
        setShowAlert: (state, action) => {
            state.showAlert = action.payload;
        },

    },
});

export const displayAlert = (title, body, type) => async (dispatch, getState) =>{
    const {tmp} = getState();
    const hasAlert = tmp.showAlert;

    if (hasAlert){
        dispatch(setShowAlert(false));
    }

    setTimeout (()=>{
        dispatch(setAlert(title, body, type));
        dispatch(setShowAlert(true));
    }, hasAlert ? 300 : 0)
}

export const fetchJob = (guid, background = false) => async dispatch => {
    try {
        if (!background) dispatch(addLoading())
        dispatch(setStatus(Status.LOADING));

        const result = await getJobByGuid(guid);
        const [job] = result?.Jobs;

        const markers = [{
            icon: setMarkerIcon(PinLocation),
            position: {
                lat: job?.Lattitude,
                lng: job?.Longitude,
            },
        }];

        const now = moment().format('h:mma');
        const isNoActiveJob = (result.Code === "000" && job.Number === null);
        const details = {
            Number: (isNoActiveJob) ? "": job.Number,
            ETA: (isNoActiveJob) ? "" : job.ETA,
            State: (isNoActiveJob) ? "" : job.State,
            LastUpdate: now,
            CreatedTime: (isNoActiveJob) ? "" : job.CreatedTime,
            Delay: (isNoActiveJob) ? "" : job.Delay,
            CommonPlaceName: (isNoActiveJob) ? "" : job.CommonPlaceName,
            Address: (isNoActiveJob) ? "" : formatAddress(job.StreetNo, job.StreetType, job.StreetName, job.Suburb),
        };

        if(details && [4,5].findIndex(x => x === details.State) > 0) {
            localStorage.setItem("HasBeenAllocated" + details.Number, 1);
        }

        if (job.State === JobStates.ENROUTE || job.State === JobStates.AT_SCENE) {
            const { ServiceProvider } = await getProvider(job.Number);

            if (ServiceProvider) {
                const provider = {
                    name: ServiceProvider?.Name,
                    hasGPS: !!ServiceProvider?.Latitude && !!ServiceProvider?.Longitude
                }

                if (provider.hasGPS) {
                    markers.push({
                        icon: setMarkerIcon(ProviderLocation),
                        position: {
                            lat: ServiceProvider?.Latitude,
                            lng: ServiceProvider?.Longitude
                        }
                    })
                }

                dispatch(setProvider(provider))
            }
        }

        dispatch(setJob({
            job: details, 
            progress: stateToProgress(job?.State)
        }));
        dispatch(setMarkers(markers));
        dispatch(setStatus(Status.SUCCESS));
    } catch (error) {
        dispatch(setStatus(Status.FAIL))
        logger.logEntry(`Fetch job error ${guid}: ${JSON.stringify(error)}`);
    } finally {
        if (!background) dispatch(removeLoading())
    }
}

export const { setMarkers, setJob, setProvider, setProgress, setAlert, setShowAlert, setStatus } = tmpSlice.actions;
export const selectMarkers = state => state.tmp.markers;
export const selectStatus = state => state.tmp.status;
export const selectProgress = state => state.tmp.progress;
export const selectJob = state => state.tmp.job;
export const selectProvider = state => state.tmp.provider;
export const selectIsShowSearch = state => state.tmp.isShowSearch;
export const selectAlert = state => state.tmp.alert;
export const selectShowAlert = state => state.tmp.showAlert;

export default tmpSlice;