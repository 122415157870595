import { lazy } from 'react';
import { Navigate, Outlet } from 'react-router';
import ChangeJobLocation from './feature/track-my-patrol/pages/change-job-location';

const Main = lazy(() => import('./components/layout/main'));
const NotFound = lazy(() => import('./components/not-found'));
const Begin = lazy(() => import('./feature/rsa/component/begin'));
const PreBegin = lazy(() => import('./feature/rsa/component/pre-begin'));
const Validate = lazy(() => import('./feature/rsa/component/validate'));
const TmpContainer = lazy(() => import('./feature/track-my-patrol/component/tmp-container'));
const JobView = lazy(() => import('./feature/track-my-patrol/pages/job-view'));
const Problem = lazy(() => import('./feature/rsa/component/problem'));
const ChooseVehicle = lazy(() => import('./feature/rsa/component/choose-vehicle'));
const ConfirmVehicle = lazy(() => import('./feature/rsa/component/confirm-vehicle'));
const Location = lazy(() => import('./feature/rsa/component/location'));
const ChooseLocation = lazy(() => import('./feature/rsa/component/choose-location'));
const UserDetails = lazy(() => import('./feature/rsa/component/user-details'));
const Review = lazy(() => import('./feature/rsa/component/review'));
const JobConfirmation = lazy(() => import('./feature/rsa/component/job-confirmation'));
const ErrorView = lazy(() => import('./feature/track-my-patrol/pages/error'));
const CancelConfirmationView = lazy(() => import('./feature/track-my-patrol/pages/cancel-confirmation'));
const NoActiveJobView = lazy(() => import('./feature/track-my-patrol/pages/no-active-job'));
const CancelView = lazy(() => import('./feature/track-my-patrol/pages/cancel-view'));

const routes = [
    {
        path: '',
        key: 'INDEX',
        element: <Main />,
        children: [
            {
                path: '/roadside-assistance',
                key: 'RSA_ROOT',
                element: <Outlet />,
                children: [
                    {
                        path: '',
                        element: <Navigate to='location' />
                    },
                    {
                        path: 'location',
                        element: <PreBegin />
                    },
                    {
                        path: 'begin',
                        element: <Begin />
                    },
                    {
                        path: 'validate',
                        element: <Validate />
                    },
                    {
                        path: 'problem',
                        element: <Problem />
                    },
                    {
                        path: 'choose-vehicle',
                        element: <ChooseVehicle />
                    },
                    {
                        path: 'confirm-vehicle',
                        element: <ConfirmVehicle />
                    },
                    {
                        path: 'location-details',
                        element: <Location />
                    },
                    {
                        path: 'choose-location',
                        element: <ChooseLocation />
                    },
                    {
                        path: 'user-details',
                        element: <UserDetails />
                    },
                    {
                        path: 'review',
                        element: <Review />
                    },
                    {
                        path: 'job-confirmation',
                        element: <JobConfirmation />
                    },
                    {
                        path: '*',
                        element: <NotFound />
                    }
                ]
            },
            {
                path: '/track-my-patrol',
                key: 'TMP_ROOT',
                element: <Outlet />,
                children: [

                    {
                        path: 'job/:guid/location',
                        element: <TmpContainer header={<h1 className='tmp-header-text font-bold m-0 p-0'>Update location</h1>} showBack={true} alertClassName='mt-5'/>,
                        children: [
                            {
                                path: '',
                                element: <ChangeJobLocation />
                            }
                        ]
                    },
                    {
                        path: 'cancel/:guid',
                        element: <TmpContainer header={<h1 className='tmp-header-text font-bold m-0 p-0'>Cancel assistance</h1>} showBack={true} alertClassName='mt-5'/>,
                        children: [
                            {
                                path: '',
                                element: <CancelView />
                            }
                        ]
                    },
                    {
                        path: '',
                        element: <TmpContainer />,
                        children: [
                            {
                                path: 'job/:guid',
                                element: <JobView />
                                    
                            },
                            {
                                path: 'error/:guid',
                                element: <ErrorView />
                            },
                            {
                                path: 'cancel-confirmation',
                                element: <CancelConfirmationView />
                            },
                            {
                                path: 'no-active-job',
                                element: <NoActiveJobView />
                            }
                        ]
                    }
                    
                ]
            },
        ]
    },

    {
        path: '*',
        key: 'NOT_ROUTE',
        element: <NotFound />
    }
]

export default routes;