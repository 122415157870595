import React, { useCallback, useEffect, useMemo } from 'react';
import Map from '../component/map';
import { useDispatch, useSelector } from 'react-redux';
import { displayAlert, fetchJob, selectJob, selectMarkers, selectProgress, selectStatus, setShowAlert } from '../tmp.slice';
import { useParams, useNavigate } from 'react-router-dom';
import NoActiveJob from '../component/no-active-job';
import RenderIf, { WhenFalse, WhenTrue } from '../../../components/render-if';
import { ProgressSteps } from '../constants/progress-step';
import { Status } from '../constants/status';
import SelectLocation from '../component/select-location';
import PinLocation from '../../../styles/images/pin-car.svg';
import { formatAddress, setMarkerIcon } from '../../../utils/helpers';
import { getLocationByLatLng, updateLocation } from '../../../services/api';
import { useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import config from '../../../config/config';
import ErrorModal from '../component/error-modal';
import LocationErrorModal from '../component/location-error-modal';

const ChangeJobLocation = () => {
    const { t } = useTranslation();
    const markers = useSelector(selectMarkers);
    const [currentMarker, setCurrentMarker] = useState([]);
    const [address, setAddress] = useState('');
    const [streetNumber, setStreetNumber] = useState('');
    const [UFI, setUFI] = useState(null);
    const job = useSelector(selectJob);
    const dispatch = useDispatch();
    const { guid } = useParams();
    const status = useSelector(selectStatus);
    const progress = useSelector(selectProgress);
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [showLocationModal, setShowLocationModal] = useState(false);
    const [isLocationErrorDisplayed, setIsLocationErrorDisplayed] = useState(false);
    const [isUseLocationEnabled, setIsUseLocationEnabled] = useState(false);

    useEffect(() => {
        if (progress === ProgressSteps.FINDING || progress === ProgressSteps.ENROUTE) {
            dispatch(displayAlert({ title: t('TMP.alert.info_update_location.title'), body: t('TMP.alert.info_update_location.body'), type: "info" }));
        }
        return ()=> dispatch(setShowAlert(false));
    }, [progress, dispatch, t])

    useEffect(() => {
        dispatch(fetchJob(guid));
    }, [guid, dispatch]);

    useEffect(() => {
        setAddress(job?.Address);
    }, [job?.Address]);
  
    useEffect(()=>{
        setCurrentMarker(_.first(markers) || null);
    }, [markers]);

    useEffect(() => {
        if (progress === 3) navigate(config.path.TMP_JOB.replace(':guid', guid));
    }, [progress, guid, navigate]);

    const handleData = useMemo(() => _.debounce(async (event) => {
        const lat = event.lat;
        const lng = event.lng;
        const result = await getLocationByLatLng(lat, lng);
        if (!_.has(result, 'Addresses')) setShowModal(true);

        if (result?.Addresses?.length > 0) {
            const [address] = result.Addresses;
            setAddress(formatAddress(address.StreetNumber, address.StreetType, address.StreetName, address.Suburb));
            setStreetNumber(address.StreetNumber);
            setUFI(address.UFI);
        } else {
            setAddress("");
            setStreetNumber("");
            setUFI(null);
        }
        if (!isUseLocationEnabled) setIsUseLocationEnabled(true);
        
    }, 300), [isUseLocationEnabled]);

    const handlePan = useCallback(event => {
        const marker = {
            icon: setMarkerIcon(PinLocation),
            position: {
                lat: event.lat,
                lng: event.lng,
            },
        };
        setCurrentMarker(marker);
        handleData(event);
    }, [handleData]);

    const handleSearchAddress = useCallback(event => {
        const marker = {
            icon: setMarkerIcon(PinLocation),
            position: {
                lat: event.lat,
                lng: event.lng,
            },
        };
        setCurrentMarker(marker);
        handleData(event);
    }, [handleData]);

    const handleUserMarkerError = useCallback(() => {
        if (isLocationErrorDisplayed) return;
        dispatch(displayAlert({  title: t('TMP.alert.enable_gps.title'), body: t('TMP.alert.enable_gps.body'), type: "info"}));
        setIsLocationErrorDisplayed(true);
    }, [dispatch, t, isLocationErrorDisplayed]);

    const updateJob = async () => {
        try {

            if (!UFI) {
                setShowLocationModal(true);
                return;
            }
            const result = await updateLocation(job?.Number, streetNumber, UFI);
            if (result.Code !== '000') {
                setShowModal(true);
            } else {
                setShowModal(false);
                navigate(config.path.TMP_JOB.replace(':guid', guid));
            }
        } catch (error) {
            setShowModal(true);
        }
    }
    
    if(!config.ShowUpdate) {
        navigate(config.path.TMP_JOB.replace(':guid', guid));
    }

    const renderJob = () => {
        return (
            <RenderIf condition={progress === ProgressSteps.NOT_ACTIVE}>
                <WhenTrue>
                    <NoActiveJob />
                </WhenTrue>
                <WhenFalse>
                    <>
                        <Map markers={currentMarker ? [currentMarker] : []} 
                            onPan={handlePan} 
                            onSearchAddress={handleSearchAddress} 
                            showCurrentLocation={true}
                            centerMaker={true}
                            zoomOnPan={false}
                            onUserMarkerError={handleUserMarkerError}
                        />
                        <SelectLocation address={address} onClick={updateJob} disabled={!isUseLocationEnabled} />
                        <ErrorModal onShow={showModal} onClose={() => setShowModal(false)} />
                        <LocationErrorModal onShow={showLocationModal} onClose={()=> setShowLocationModal(false)} />
                    </>
                </WhenFalse>
            </RenderIf>
        )
    }

    const renderError = () => {
        navigate(config.Path.TMP_ERROR.replace(':guid', guid));
        return <></>;
    }

    if (status === Status.IDLE) return <div>Please wait...</div>
    if (progress === ProgressSteps.NOT_ACTIVE && status === Status.FAIL) return renderError();
    return renderJob();
}

export default ChangeJobLocation;