import { useMemo } from "react";
import _ from 'lodash';

const SearchBoxItem = ({description, matched_substrings, onClick}) => {

    const text = useMemo(()=> {
        return matched_substrings.reduce((acc, curr, index, array)=>{
            const offset = curr.offset - (index === 0 ? 0 : array[index-1].offset + array[index-1].length)
            const final = acc.remaining.substr(0, offset + curr.length);
            acc.remaining = acc.remaining.substr(offset + curr.length);

            if (offset !== 0){
                acc.result.push(final.substr(0, offset));
            }
            acc.result.push(<strong>{final.substr(offset)}</strong>);
            if (index === array.length -1){
                acc.result.push(acc.remaining);
                acc.remaining = '';
            };

            return acc;
        }, {remaining: description, result: []}).result;
        
    }, [description, matched_substrings]);

    const handleClick = () => {
        if (_.isFunction(onClick)) onClick();
    }

    return  <div className="seach-box-item p-3 d-flex" role="button" onClick={handleClick}>
                <div className="d-flex justify-content-center"><span className='icon-maps icon-maps-pin' /></div>
                <div className='ps-2 text-truncate'>
                    {text && text.map((item, index) => <span key={index}>{item}</span>)}
                </div>
            </div>
}

export default SearchBoxItem;