import React, { useMemo, useEffect, useState } from 'react';
import BeaconIcon from '../../../styles/images/track-elements-geo-dot.svg';
import { Marker } from '@react-google-maps/api';
import _ from 'lodash';

const UserMarker = ({onError, onChange}) =>{
    const [lat, setLat] = useState(null);
    const [lng, setLng] = useState(null);

    const positionMove = useMemo(()=> _.throttle(success =>{
        const {coords: {latitude, longitude}} = success;
        if (latitude === lat && longitude === lng) return;
        
        setLat(latitude);
        setLng(longitude);
        if (_.isFunction(onChange)){
            onChange({lat: latitude, lng: longitude});
        }
    }, 3000) , [lat, lng, onChange]);

    useEffect(()=>{
        const watch = navigator.geolocation.watchPosition(
            positionMove,
            error => {
                if (_.isFunction(onError)){
                    onError(error);
                }
            }
        );
        return () => navigator.geolocation.clearWatch(watch);
    }, [lat, lng, onError, onChange, positionMove]); 

    if (!lat || !lng ) return null;

    return  <Marker zIndex={0} icon={BeaconIcon} position={{lat, lng}} />
}

export default UserMarker;