import {GetApiWrapper, PostApiWrapper} from "./apiWrapper";

const services = {
    validateNumber: '/membership/webportal/validate/number',
    serviceTypes: '/serviceTypes/webportal/types',
    vehiclesMake: '/vehicles/webportal/makes',
    vehiclesModel: '/vehicles/webportal/models/',
    vehiclesYear: '/vehicles/webportal/years/',
    vehiclesColor: '/vehicles/webportal/colours',
    address: '/addresses/webportal/address',
    add: '/jobs/webportal/add',
    update: '/jobs/webportal/update',
    email: '/sendEmail',
    jobByGuid: '/jobs/webportal/guid',
    providers: '/jobs/webportal/providers',
    location: '/addresses/webportal/location',
    cancel: '/jobs/webportal/cancel',
}

const getMemberData = async (number, postcode) => await GetApiWrapper(services.validateNumber, {number: number, postcode: postcode});
const getProblemsData = async () => await GetApiWrapper(services.serviceTypes);
const getVehiclesMakeData = async () => await GetApiWrapper(services.vehiclesMake);
const getVehiclesModelData = async (make) => await GetApiWrapper(`${services.vehiclesModel}${make}`);
const getVehiclesYearData = async (make) => await GetApiWrapper(`${services.vehiclesYear}${make}`);
const getVehiclesColorData = async () => await GetApiWrapper(services.vehiclesColor);
const getLocationDetailsData = async (details) => await GetApiWrapper(services.address, { number: details.name, street: details.street, suburb: details.suburb});
const postMemberDetails = async(details) => await PostApiWrapper(services.add, {...details});
const postJobConfirmation = async(details) => await PostApiWrapper(services.email, {...details});
const getJobByGuid = async (guid) => await GetApiWrapper(`${services.jobByGuid}/${guid}`)
const getProvider = async number => await GetApiWrapper(services.providers, {number});
const getLocationByLatLng = async (latitude, longitude) => await GetApiWrapper(services.location, {latitude, longitude});
const updateLocation = async (jobNumber, AddressStreetNumber, AddressUFI) => await PostApiWrapper(`${services.update}?number=${jobNumber}`, {AddressStreetNumber, AddressUFI});
const postCancelJob = async(details) => await PostApiWrapper(services.cancel, {...details});

export {
    getMemberData,
    getProblemsData,
    getVehiclesMakeData,
    getVehiclesModelData,
    getVehiclesYearData,
    getVehiclesColorData,
    getLocationDetailsData,
    postMemberDetails,
    postJobConfirmation,
    getJobByGuid,
    getProvider,
    getLocationByLatLng,
    updateLocation,
    postCancelJob,
};