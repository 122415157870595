import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PinIcon from '../../../styles/images/icon-pin.png';

const NoActiveJob = () =>{
    const {t} = useTranslation();
    return  <div className='no-active-job h-100 container mx-auto pe-0'>
                <Row>
                    <Col xs='12' className='text-center'>
                        <img src={PinIcon} alt="no active job"/>
                    </Col>
                </Row>
                <Row>
                    <Col xs='12' className='px-5 text-center'>
                        <h2 className='font-bold'>{t('TMP.no_active_job')}</h2>
                    </Col>
                </Row>
            </div>
}

export default NoActiveJob;