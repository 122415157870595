import _ from 'lodash';
import { JobStates } from '../feature/track-my-patrol/constants/job-state';
import { ProgressSteps } from '../feature/track-my-patrol/constants/progress-step';

export const formatAddress = (streetNo, ...addresses) => `${streetNo} ${_.compact(addresses.map(add => add.trim())).join(", ")}`.trim();

export const stateToProgress = (state) =>{
    switch (state){
        case JobStates.PENDING:
        case JobStates.ALLOCATED:
        case JobStates.SENT:
        case JobStates.JOB_ACK:
            return ProgressSteps.FINDING;
        case JobStates.ENROUTE:
            return ProgressSteps.ENROUTE;
        case JobStates.AT_SCENE:
            return ProgressSteps.AT_SCENE;
        default: 
            return ProgressSteps.NOT_ACTIVE
    }
}

export const setMarkerIcon = icon => ({
    url: icon,
    scaledSize: {height: 54, width: 48}
})