import React from 'react';

export const WhenTrue = props =>{
    React.Children.only(props.children);
    return props.children
}

export const WhenFalse = props =>{
    React.Children.only(props.children);
    return props.children
}

const RenderIf = props =>{
    if (!props.children) return null;
    if (props.condition === undefined) return props.children;

    const condition = !!props.condition;
    const children = React.Children.toArray(props.children);
    const render = children.filter(item => ((item.type === WhenFalse) !== condition));

    return render;
}

export default RenderIf;