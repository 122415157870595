import Config from "../config/config";
import axios from 'axios';
var tokenKey = "token";

class Logger {
  logEntry(message) {
    const url = `${Config.services.backend.url}/log`;
    const body = {
      type: "Error",
      title: 'RSA Desktop React Error',
      clientName: process.env.STAGE,
      message: message
    }
    return axios.post(url, body, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem(tokenKey)}`
      }
    });
  }
}

export default Logger;
