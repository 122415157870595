
import config from "./../config/config";

const baseUrl = config.services.backend.url;
var tokenKey = "token";
var tokenExpiryKey = "token-expiry";

const getToken = async () => {
    var uri = new URL(`${config.services.token.url}`);
    var dateNow = new Date();
    if (localStorage.getItem(tokenKey) && localStorage.getItem(tokenExpiryKey) && dateNow < new Date(localStorage.getItem(tokenExpiryKey))) {
        return;
    }
    return await fetch(uri, {
        method: 'POST',
        headers: new Headers({
            'Authorization': 'Basic ' + Buffer.from(`${config.services.token.basic}`).toString("base64"),
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        }),
        body: encodeURIComponent("grant_type") + "=" + encodeURIComponent("client_credentials")
    })
        .then(response => response.json())
        .then(data => {
            localStorage.setItem("token", data.access_token);
            var t = new Date();
            t.setSeconds(t.getSeconds() + data.expires_in);
            localStorage.setItem("token-expiry", t);
        }).catch((err) => {
            return err;
        });
}

const GetApiWrapper = async (apiUrl, params) => {
    await getToken();

    var uri = new URL(`${baseUrl}${apiUrl}`),
        uriParams = { ...params }
    Object.keys(uriParams).forEach(key => uri.searchParams.append(key, uriParams[key]))
    var fetchData = await fetch(uri, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem(tokenKey)}`
        }
    })
        .then(response => response.json())
        .then(data => {
            return data;
        }).catch((err) => {
            return err;
        });

    return fetchData;
}

const PostApiWrapper = async (apiUrl, params) => {
    await getToken();

    var uri = new URL(`${baseUrl}${apiUrl}`)

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem(tokenKey)}`
        },
        body: JSON.stringify(params)
    };
    var postData = await fetch(uri, requestOptions)
        .then(response => response.json())
        .then(data => {
            return data;
        }).catch((err) => {
            return err;
        });

    return postData;
}

export {
    GetApiWrapper,
    PostApiWrapper
};
