export const JobStates = {
    PENDING: 1,
    ALLOCATED: 2,
    SENT: 3,
    JOB_ACK: 4,
    ENROUTE: 5,
    AT_SCENE: 6,
    HELD: 8,
    QUERY: 9,
    COMPLETE: 10,
    CANCALLED: 11,
}