import { Anchor, Modal, Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CloseIcon from '../../../styles/images/icon-tiny-close.svg';
import ErrorIcon from '../../../styles/images/icon-notifications-warning.svg';

const ErrorModal = ({ onShow, onClose }) => {
  const { t } = useTranslation();

  return (
    <>
      <Modal className="error" show={onShow} onHide={onClose} animation={false}>
        <Modal.Header>
          <Anchor onClick={onClose}>
            <img src={CloseIcon} alt="Close Icon" />
          </Anchor>
        </Modal.Header>
        <Modal.Body>
          <Container className="flex-grow-1 p-0 position-relative mx-auto">
            <Row className="h-100">
              <Col xs='12' className="h-100 h-sm-75 d-flex flex-column position-relative">
                <div className="message-continer">
                  <img className="error-icon" src={ErrorIcon} alt="Error Icon" />
                  <h2>{t("TMP.error.modal_body_header")}</h2>
                  <p className="description">{t("TMP.error.error_description")}</p>
                  <p className="request-active">{t("TMP.error.request_being_active")}</p>
                  <p className="call-us">{t("TMP.error.call_us.opening")} <Anchor href="tel:1300729062">1300 729 062</Anchor> {t("TMP.error.call_us.closing")}</p>
                  <button className='btn btn-blue cancel' onClick={onClose}>{t("TMP.error.modal_btn_close")}</button>
                </div>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ErrorModal;