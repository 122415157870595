import { createSlice } from '@reduxjs/toolkit';

export const rsaSlice = createSlice({
    name: 'rsa',
    initialState: {
        preBegin : '',
        begin: '',
        validate: {},
        memberData: {},
        problem: {},
        vehicle: {},
        location: {},
        user_details: {},
        review: {},
        problemList: []
    },
    reducers: {
        setPreBegin: (state, action) =>{
            state.job = action.payload
        },
        updateUserDetails: (state, action) => {
            const key = (Object.keys(action.payload)[0])
            state[key] = action.payload[key]
        }
    },
});

export const { updateUserDetails } = rsaSlice.actions;

export default rsaSlice;