import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SearchIcon from '../../../styles/images/icon-search.svg';
import SearchCloseIcon from '../../../styles/images/icon-search-close.svg';
import _ from 'lodash';
import RenderIf, { WhenFalse, WhenTrue } from '../../../components/render-if';
import SearchBoxItem from './search-box-item';
import { getPlaceDetails, googleAutocomplete } from '../../../services/google-auto-complete';

const Searchbox = ({onSelectChange, map}) =>{
    const inputRef = useRef(null);
    const {t} = useTranslation();
    const [showClear, setShowClear] = useState(false);
    const [results, setResults] = useState([]);
    const [showResults, setShowResults] = useState(false);

    const getPrediction = useMemo(() => _.debounce(async (text)=>{
        setShowResults(false);
        try{
            const results = await googleAutocomplete(text);
            setResults(results);
        }
        catch (err) {
            setResults([])
        }finally{
            setShowResults(true);
        }
    }, 300), []);

    const handleChange = useCallback(async event => {
        const hasValue = !!event.target.value;
        setShowResults(false);
        getPrediction(event.target.value);
        setShowClear(hasValue);
    }, [getPrediction]);


    const handleClear = useCallback(() => {
        inputRef.current.value = "";
        setShowResults(false);
        setShowClear(false);
        setResults([]);
    }, []);

    const handleSelectItem = (item) => async () => {
        setShowResults(false);
        setResults([]);
        inputRef.current.value = item.description;

        if (!_.isFunction(onSelectChange)) return;
        const result = await getPlaceDetails(map, item.place_id);
        const lat = result.geometry.location.lat();
        const lng = result.geometry.location.lng();
        onSelectChange({lat, lng});  
    };

    const handleKeyUp = event => {
        if (event.key === 'Enter' && showResults && results?.length){
            handleSelectItem(results[0])()
            return
        }

        if (event.key === 'Escape'){
            setShowResults(false);
            return
        }
    }

    return  <div className="d-flex flex-column">
                <div className='position-relative'>
                    
                    <div className='p-3 w-100'>
                        <input
                        ref ={inputRef}
                        type="text"
                        placeholder={t('TMP.form.placeholders.search-location')}
                        className='search-location-input w-100 border-0 rounded shadow'
                        onChange={handleChange}
                        onKeyUp={handleKeyUp}
                        />
                    </div>
                    <div className='position-absolute d-flex h-100 align-items-center top-0 p-3 ms-2'>
                        <img src={SearchIcon} alt="search" className='ms-1 position-absolute'/>
                    </div>
                    <RenderIf condition={showClear}>
                        <div className='position-absolute d-flex h-100 align-items-center top-0 p-3 ms-2 end-0'>
                        <button className='btn w-auto' onClick={handleClear}><img src={SearchCloseIcon} alt="clear search" /></button>
                        </div>
                    </RenderIf>
                </div>
                <RenderIf condition={showResults && inputRef?.current?.value}>
                    <div style={{zIndex: 1}} className='p-3 pt-0 w-100 no-search-result-wrapper'>
                        <RenderIf condition={results}>
                            <WhenTrue>
                                <div className="search-result-list text-nowrap rounded-bottom shadow">
                                    { 
                                    results && results.map((result, index) => <SearchBoxItem onClick={handleSelectItem(result)} key={index} {...result} />)
                                    }
                                </div>
                            </WhenTrue>

                            <WhenFalse>
                                <div className="no-search-result p-3 rounded-bottom shadow">
                                {t('TMP.no_search_result')}
                                </div>
                            </WhenFalse>
                            
                        </RenderIf>

                    </div>
                </RenderIf>
            </div>
}


export default Searchbox;